import PropTypes from 'prop-types';
import React from 'react';
import Race from './Race';
import * as styles from './Races.module.css';

export default function Races({ races = [] }) {
  return (
    <div className={styles.racesContainer}>
      {races.map((race) => (
        <Race key={race.id} {...race} />
      ))}
    </div>
  );
}

Races.propTypes = {
  races: PropTypes.arrayOf(PropTypes.shape()),
};
