import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import useCountdown from '../../hooks/useCountdown';
import * as styles from './Countdown.module.css';

export default function Countdown({ time, className = '' }) {
  const countdown = useCountdown(time);

  return (
    <span
      className={clsx(
        styles.countdown,
        {
          [styles.urgent]: countdown.urgent,
        },
        className
      )}
    >
      {countdown.label}
    </span>
  );
}

Countdown.propTypes = {
  time: PropTypes.string,
  className: PropTypes.string,
};
