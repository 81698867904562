import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Title from '../Title/Title';

export default function Iframe({ title, url }) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <iframe
        src={`${url}#zoom=FitH`}
        title={title}
        height="800px"
        width="100%"
      />
    </Container>
  );
}

Iframe.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};
