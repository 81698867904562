import PropTypes from 'prop-types';
import React from 'react';
import useAppState from '../../hooks/useAppState';
import Container from '../Container/Container';
import Title from '../Title/Title';
import Races from './Races';

export default function NextToJump({ title, url }) {
  const { nextToJump } = useAppState();

  return nextToJump?.length > 0 ? (
    <Container>
      <Title>{title}</Title>
      <Races races={nextToJump.map((race) => ({ ...race, url }))} />
    </Container>
  ) : null;
}

NextToJump.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};
