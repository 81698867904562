const fractions = [
  {
    min: 1.05,
    max: 1.2,
    value: '1/9',
  },
  {
    min: 1.2,
    max: 1.4,
    value: '1/5',
  },
  {
    min: 1.4,
    max: 1.5,
    value: '2/5',
  },
  {
    min: 1.5,
    max: 1.6,
    value: '1/2',
  },
  {
    min: 1.6,
    max: 1.8,
    value: '3/5',
  },
  {
    min: 1.8,
    max: 2.0,
    value: '4/5',
  },
  {
    min: 2.0,
    max: 2.2,
    value: '1',
  },
  {
    min: 2.2,
    max: 2.4,
    value: '6/5',
  },
  {
    min: 2.4,
    max: 2.5,
    value: '7/5',
  },
  {
    min: 2.5,
    max: 2.6,
    value: '3/2',
  },
  {
    min: 2.6,
    max: 2.8,
    value: '8/5',
  },
  {
    min: 2.8,
    max: 3.0,
    value: '9/5',
  },
  {
    min: 3.0,
    max: 3.5,
    value: '2',
  },
  {
    min: 3.5,
    max: 4.0,
    value: '5/2',
  },
  {
    min: 4.0,
    max: 4.5,
    value: '3',
  },
  {
    min: 4.5,
    max: 5.0,
    value: '7/2',
  },
  {
    min: 5.0,
    max: 5.5,
    value: '4',
  },
  {
    min: 5.5,
    max: 6.0,
    value: '9/2',
  },
  {
    min: 6.0,
    max: 7.0,
    value: '5',
  },
  {
    min: 7.0,
    max: 8.0,
    value: '6',
  },
  {
    min: 8.0,
    max: 9.0,
    value: '7',
  },
  {
    min: 9.0,
    max: 10.0,
    value: '8',
  },
  {
    min: 10.0,
    max: 11.0,
    value: '9',
  },
  {
    min: 11.0,
    max: 12.0,
    value: '10',
  },
  {
    min: 12.0,
    max: 13.0,
    value: '11',
  },
  {
    min: 13.0,
    max: 14.0,
    value: '12',
  },
  {
    min: 14.0,
    max: 15.0,
    value: '13',
  },
  {
    min: 15.0,
    max: 16.0,
    value: '14',
  },
  {
    min: 16.0,
    max: 17.0,
    value: '15',
  },
  {
    min: 17.0,
    max: 18.0,
    value: '16',
  },
  {
    min: 18.0,
    max: 19.0,
    value: '17',
  },
  {
    min: 19.0,
    max: 20.0,
    value: '18',
  },
  {
    min: 20.0,
    max: 21.0,
    value: '19',
  },
  {
    min: 21.0,
    max: 22.0,
    value: '20',
  },
  {
    min: 22.0,
    max: 23.0,
    value: '21',
  },
  {
    min: 23.0,
    max: 24.0,
    value: '22',
  },
  {
    min: 24.0,
    max: 25.0,
    value: '23',
  },
  {
    min: 25.0,
    max: 26.0,
    value: '24',
  },
  {
    min: 26.0,
    max: 27.0,
    value: '25',
  },
  {
    min: 27.0,
    max: 28.0,
    value: '26',
  },
  {
    min: 28.0,
    max: 29.0,
    value: '27',
  },
  {
    min: 29.0,
    max: 30.0,
    value: '28',
  },
  {
    min: 30.0,
    max: 31.0,
    value: '29',
  },
  {
    min: 31.0,
    max: 32.0,
    value: '30',
  },
  {
    min: 32.0,
    max: 33.0,
    value: '31',
  },
  {
    min: 33.0,
    max: 34.0,
    value: '32',
  },
  {
    min: 34.0,
    max: 35.0,
    value: '33',
  },
  {
    min: 35.0,
    max: 36.0,
    value: '34',
  },
  {
    min: 36.0,
    max: 37.0,
    value: '35',
  },
  {
    min: 37.0,
    max: 38.0,
    value: '36',
  },
  {
    min: 38.0,
    max: 39.0,
    value: '37',
  },
  {
    min: 39.0,
    max: 40.0,
    value: '38',
  },
  {
    min: 40.0,
    max: 41.0,
    value: '39',
  },
  {
    min: 41.0,
    max: 42.0,
    value: '40',
  },
  {
    min: 42.0,
    max: 43.0,
    value: '41',
  },
  {
    min: 43.0,
    max: 44.0,
    value: '42',
  },
  {
    min: 44.0,
    max: 45.0,
    value: '43',
  },
  {
    min: 45.0,
    max: 46.0,
    value: '44',
  },
  {
    min: 46.0,
    max: 47.0,
    value: '45',
  },
  {
    min: 47.0,
    max: 48.0,
    value: '46',
  },
  {
    min: 48.0,
    max: 49.0,
    value: '47',
  },
  {
    min: 49.0,
    max: 50.0,
    value: '48',
  },
  {
    min: 50.0,
    max: 51.0,
    value: '49',
  },
  {
    min: 51.0,
    max: 56.0,
    value: '50',
  },
  {
    min: 56.0,
    max: 61.0,
    value: '55',
  },
  {
    min: 61.0,
    max: 66.0,
    value: '60',
  },
  {
    min: 66.0,
    max: 71.0,
    value: '65',
  },
  {
    min: 71.0,
    max: 76.0,
    value: '70',
  },
  {
    min: 76.0,
    max: 81.0,
    value: '75',
  },
  {
    min: 81.0,
    max: 86.0,
    value: '80',
  },
  {
    min: 86.0,
    max: 91.0,
    value: '85',
  },
  {
    min: 91.0,
    max: 96.0,
    value: '90',
  },
  {
    min: 96.0,
    max: 100.0,
    value: '95',
  },
  {
    min: 100.0,
    max: 101.0,
    value: '99',
  },
  {
    min: 101.0,
    max: 111.0,
    value: '100',
  },
  {
    min: 111.0,
    max: 121.0,
    value: '110',
  },
  {
    min: 121.0,
    max: 131.0,
    value: '120',
  },
  {
    min: 131.0,
    max: 141.0,
    value: '130',
  },
  {
    min: 141.0,
    max: 151.0,
    value: '140',
  },
  {
    min: 151.0,
    max: 161.0,
    value: '150',
  },
  {
    min: 161.0,
    max: 171.0,
    value: '160',
  },
  {
    min: 171.0,
    max: 181.0,
    value: '170',
  },
  {
    min: 181.0,
    max: 191.0,
    value: '180',
  },
  {
    min: 191.0,
    max: 201.0,
    value: '190',
  },
  {
    min: 201.0,
    max: 211.0,
    value: '200',
  },
  {
    min: 211.0,
    max: 221.0,
    value: '210',
  },
  {
    min: 221.0,
    max: 231.0,
    value: '220',
  },
  {
    min: 231.0,
    max: 241.0,
    value: '230',
  },
  {
    min: 241.0,
    max: 251.0,
    value: '240',
  },
  {
    min: 251.0,
    max: 261.0,
    value: '250',
  },
  {
    min: 261.0,
    max: 271.0,
    value: '260',
  },
  {
    min: 271.0,
    max: 281.0,
    value: '270',
  },
  {
    min: 281.0,
    max: 291.0,
    value: '280',
  },
  {
    min: 291.0,
    max: 301.0,
    value: '290',
  },
  {
    min: 301.0,
    max: 311.0,
    value: '300',
  },
  {
    min: 311.0,
    max: 321.0,
    value: '310',
  },
  {
    min: 321.0,
    max: 331.0,
    value: '320',
  },
  {
    min: 331.0,
    max: 341.0,
    value: '330',
  },
  {
    min: 341.0,
    max: 351.0,
    value: '340',
  },
  {
    min: 351.0,
    max: 361.0,
    value: '350',
  },
  {
    min: 361.0,
    max: 371.0,
    value: '360',
  },
  {
    min: 371.0,
    max: 381.0,
    value: '370',
  },
  {
    min: 381.0,
    max: 391.0,
    value: '380',
  },
  {
    min: 391.0,
    max: 401.0,
    value: '390',
  },
  {
    min: 401.0,
    max: 411.0,
    value: '400',
  },
  {
    min: 411.0,
    max: 421.0,
    value: '410',
  },
  {
    min: 421.0,
    max: 431.0,
    value: '420',
  },
  {
    min: 431.0,
    max: 441.0,
    value: '430',
  },
  {
    min: 441.0,
    max: 451.0,
    value: '440',
  },
  {
    min: 451.0,
    max: 461.0,
    value: '450',
  },
  {
    min: 461.0,
    max: 471.0,
    value: '460',
  },
  {
    min: 471.0,
    max: 481.0,
    value: '470',
  },
  {
    min: 481.0,
    max: 491.0,
    value: '480',
  },
  {
    min: 491.0,
    max: 501.0,
    value: '490',
  },
  {
    min: 501.0,
    max: 551.0,
    value: '500',
  },
  {
    min: 551.0,
    max: 601.0,
    value: '550',
  },
  {
    min: 601.0,
    max: 651.0,
    value: '600',
  },
  {
    min: 651.0,
    max: 701.0,
    value: '650',
  },
  {
    min: 701.0,
    max: 751.0,
    value: '700',
  },
  {
    min: 751.0,
    max: 801.0,
    value: '750',
  },
  {
    min: 801.0,
    max: 851.0,
    value: '800',
  },
  {
    min: 851.0,
    max: 901.0,
    value: '850',
  },
  {
    min: 901.0,
    max: 951.0,
    value: '900',
  },
  {
    min: 951.0,
    max: 999.0,
    value: '950',
  },
  {
    min: 999.0,
    max: Infinity,
    value: '999',
  },
];

export default fractions;
