import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../Button/Button';
import Container from '../Container/Container';
import Icon from '../Icon/Icon';
import Title from '../Title/Title';
import useMeetings from '../../hooks/useMeetings';
import * as styles from './RacingCalendar.module.css';
import CalendarEvent from './CalendarEvent';
import meetingTypes from '../../constants/meetingTypes';
import StyledMarkdown from '../Markdown/StyledMarkdown';

export default function RacingCalendar({
  location,
  title,
  url,
  description,
  buttonColor,
  fontColor,
  blacklistedTracks = [],
}) {
  const params = new URLSearchParams(location?.search);
  const date = params.get('date');

  const [selectedDate] = useState(date ? dayjs(date).tz() : dayjs().tz());
  const meetings = useMeetings({
    firstRaceFrom: selectedDate.startOf('day'),
    to: selectedDate.endOf('day'),
  });
  const [showThoroughbred, setShowThoroughbred] = useState(true);
  const [showHarness, setShowHarness] = useState(true);

  const filteredMeetings =
    meetings?.filter(
      (meeting) =>
        [
          showThoroughbred ? meetingTypes.THOROUGHBRED : null,
          showHarness ? meetingTypes.HARNESS : null,
        ].includes(meeting.type) &&
        !blacklistedTracks?.some((track) => track.name === meeting.track.name)
    ) || [];

  return (
    <Container>
      <Title>{title}</Title>
      <div>
        <div>
          <div className={styles.messageContainer}>
            {description ? <StyledMarkdown description={description} /> : null}
          </div>
        </div>
        <div className={styles.header}>
          <h3 className={styles.title}>
            {selectedDate.format('dddd MMMM DD, YYYY')}
          </h3>
          <div className={styles.filters}>
            <Button
              className={styles.filterButton}
              theme="secondary"
              onClick={() => setShowThoroughbred((prev) => !prev)}
              style={
                showThoroughbred
                  ? { backgroundColor: buttonColor, color: fontColor }
                  : {}
              }
            >
              <Icon name={meetingTypes.THOROUGHBRED} />
            </Button>
            <Button
              className={styles.filterButton}
              theme="secondary"
              onClick={() => setShowHarness((prev) => !prev)}
              style={
                showHarness
                  ? { backgroundColor: buttonColor, color: fontColor }
                  : {}
              }
            >
              <Icon name={meetingTypes.HARNESS} />
            </Button>
          </div>
        </div>
        {filteredMeetings.length === 0 ? (
          <span>No data available yet.</span>
        ) : (
          <ul className={styles.list}>
            {filteredMeetings
              .sort((first, next) =>
                first.track.name > next.track.name ? 1 : -1
              )
              .map((meeting) => (
                <li key={meeting.id} className={styles.listItem}>
                  <CalendarEvent
                    meeting={meeting}
                    url={url}
                    fontColor={fontColor}
                    buttonColor={buttonColor}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </Container>
  );
}

RacingCalendar.propTypes = {
  location: PropTypes.shape().isRequired,
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  buttonColor: PropTypes.string,
  fontColor: PropTypes.string,
  blacklistedTracks: PropTypes.arrayOf(PropTypes.string),
};
