import clsx from 'clsx';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Link from '../Link';
import Competitor from '../Competitor/Competitor';
import Icon from '../Icon/Icon';
import * as styles from './CalendarEvent.module.css';
import Countdown from '../Countdown/Countdown';

export default function CalendarEvent({
  meeting,
  url,
  buttonColor,
  fontColor,
}) {
  const [selectedRace, setSelectedRace] = useState(
    meeting.races.filter((race) => race.status === 'OPEN')?.[0] ||
      meeting.races[meeting.races.length - 1]
  );
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <button
        type="button"
        onClick={() => setExpanded((prev) => !prev)}
        className={styles.meetingButton}
        style={{ backgroundColor: buttonColor, color: fontColor }}
      >
        <Icon name={meeting.type} />
        <span className={styles.track}>
          {meeting.track.name} ·{' '}
          {dayjs(meeting.races[0].startTime).tz().format('h:mm a')}
        </span>
      </button>
      {expanded ? (
        <div className={styles.content}>
          <div className={styles.races}>
            {meeting.races.map((race) => {
              const selected = selectedRace.id === race.id;

              return (
                <button
                  type="button"
                  key={race.id}
                  className={clsx(styles.raceButton, styles[race.status], {
                    [styles.selected]: selected,
                  })}
                  onClick={() => setSelectedRace(race)}
                  style={
                    selected
                      ? {
                          backgroundColor: buttonColor,
                          color: fontColor,
                          borderColor: buttonColor,
                        }
                      : {}
                  }
                >
                  {race.number}
                </button>
              );
            })}
          </div>
          <div>
            <div className={styles.titleContainer}>
              <h2>
                {meeting.track.name} R{selectedRace.number}
              </h2>
              {selectedRace.status === 'OPEN' ? (
                <Countdown time={selectedRace.startTime} />
              ) : null}
            </div>
            <ul className={styles.competitors}>
              {selectedRace.competitors.map((competitor) => (
                <li key={competitor.tabNo} className={styles.competitor}>
                  <Competitor competitor={competitor} showTrainer>
                    {url && selectedRace.status === 'OPEN' ? (
                      <Link href={url} className={styles.ctaButton}>
                        Bet now
                      </Link>
                    ) : null}
                  </Competitor>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
}

CalendarEvent.propTypes = {
  meeting: PropTypes.shape().isRequired,
  url: PropTypes.string,
  fontColor: PropTypes.string,
  buttonColor: PropTypes.string,
};
