import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Title from '../Title/Title';
import StyledMarkdown from './StyledMarkdown';

export default function Markdown({ title, description }) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <StyledMarkdown description={description} />
    </Container>
  );
}

Markdown.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
