import PropTypes from 'prop-types';
import React from 'react';
import fractions from '../../constants/fractions';
import Link from '../Link';
import * as styles from './Odds.module.css';

export default function Odds({ url, prices, type }) {
  const price = prices?.find((p) => p.type === type)?.price || null;

  const formatFractional = (odds) => {
    if (odds > 0) {
      const firstFraction = fractions.find(
        (fraction) => odds >= fraction.min && odds < fraction.max
      );
      return firstFraction?.value || '-';
    }

    if (odds === 0) return (+'0').toFixed(2);

    return '-';
  };

  return (
    <Link href={url} className={styles.oddsButton}>{`${
      formatFractional(price) || 'Bet now'
    }`}</Link>
  );
}

Odds.propTypes = {
  url: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.shape()),
  type: PropTypes.string,
};
