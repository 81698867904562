import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Title from '../Title/Title';
import GoogleMap from '../GoogleMap/GoogleMap';
import Link from '../Link';
import * as styles from './Locations.module.css';

// This component is run on build
export default function Locations({ data, pageContext }) {
  const { title } = data;
  const { locations } = pageContext;

  const premierVenues = locations.reduce(
    (aggregated, location) => [
      ...aggregated,
      ...location.venues
        .filter((venue) => venue.premier)
        .map((venue) => ({
          ...venue,
          locationSlug: `/locations${location.slug}`,
        })),
    ],
    []
  );

  return (
    <>
      <Container>
        <Title>{title}</Title>
        {premierVenues.length > 0 ? (
          <div className={styles.premierVenuesContainer}>
            {premierVenues.map((venue) => (
              <div key={venue.name}>
                <Link href={venue.locationSlug}>
                  <strong>{venue.name}</strong>
                </Link>
                <p>{venue.address}</p>
                {venue.phone ? <span>{venue.phone}</span> : null}
              </div>
            ))}
          </div>
        ) : null}
      </Container>
      <GoogleMap
        markers={locations.reduce(
          (aggregated, location) => [...aggregated, ...location.venues],
          []
        )}
      />
      <Container>
        <div className={styles.otherVenuesContainer}>
          {locations.map((location) => (
            <div key={location.slug}>
              <Link href={`/locations${location.slug}`}>
                <strong>{location.title}</strong>
              </Link>
              {location.venues.map((venue) => (
                <div key={venue.name}>
                  <p>
                    <strong>{venue.name}</strong>
                  </p>
                  <span>{venue.address}</span>
                  <br />
                  {venue.phone ? <span>{venue.phone}</span> : null}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

Locations.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};
