import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import DefaultSilkImg from './default_silk.png';
import * as styles from './Silk.module.css';

export default function Silk({ competitor }) {
  return (
    <div className={styles.silkContainer}>
      {competitor.barrier ? (
        <div
          className={clsx(
            styles.barrier,
            styles[`number${competitor.barrier}`]
          )}
        >
          <span>{competitor.barrier}</span>
        </div>
      ) : null}
      <div className={styles.silk}>
        <img
          className={styles.silkSpritesheet}
          src={competitor.silksURL}
          alt={competitor.name}
          onError={(event) => {
            // eslint-disable-next-line no-param-reassign
            event.target.src = DefaultSilkImg;
          }}
        />
      </div>
    </div>
  );
}

Silk.propTypes = {
  competitor: PropTypes.shape().isRequired,
};
