import PropTypes from 'prop-types';
import React from 'react';
import Silk from '../Silk/Silk';
import * as styles from './Competitor.module.css';

export default function Competitor({
  competitor,
  children,
  showTrainer = false,
}) {
  return (
    <div className={styles.competitor}>
      <Silk competitor={competitor} />
      <div className={styles.nameContainer}>
        <span
          className={styles.name}
        >{`${competitor.tabNo}. ${competitor.name}`}</span>
        <span className={styles.jockey}>
          {[
            competitor.jockey ? `J: ${competitor.jockey}` : null,
            showTrainer && competitor.trainer
              ? `T: ${competitor.trainer}`
              : null,
          ]
            .filter((item) => !!item)
            .join(' · ')}
        </span>
      </div>
      {children}
    </div>
  );
}

Competitor.propTypes = {
  competitor: PropTypes.shape().isRequired,
  children: PropTypes.node,
  showTrainer: PropTypes.bool,
};
