// extracted by mini-css-extract-plugin
export const silkContainer = "Silk-module--silkContainer--3e6ew";
export const silk = "Silk-module--silk--30Dfn";
export const silkSpritesheet = "Silk-module--silkSpritesheet--15GwT";
export const barrier = "Silk-module--barrier---uMLt";
export const number1 = "Silk-module--number1--1gwtO";
export const number2 = "Silk-module--number2--1rt1m";
export const number3 = "Silk-module--number3--xTlct";
export const number4 = "Silk-module--number4--1PWIf";
export const number5 = "Silk-module--number5--1I2fE";
export const number6 = "Silk-module--number6--2M-mZ";
export const number7 = "Silk-module--number7--1XwvS";
export const number8 = "Silk-module--number8--1aUmV";
export const number9 = "Silk-module--number9--13tD7";
export const number10 = "Silk-module--number10--1X7SM";
export const number11 = "Silk-module--number11--2ISYP";
export const number12 = "Silk-module--number12--PXs1k";
export const number13 = "Silk-module--number13--156Kn";
export const number14 = "Silk-module--number14--C5LPW";
export const number15 = "Silk-module--number15--1Nm1a";
export const number16 = "Silk-module--number16--1Vong";
export const number17 = "Silk-module--number17--3YuUz";
export const number18 = "Silk-module--number18--2s_9S";
export const number19 = "Silk-module--number19--39Dff";
export const number20 = "Silk-module--number20--16z3Y";
export const number21 = "Silk-module--number21--1NxWI";
export const number22 = "Silk-module--number22--ezIb3";
export const number23 = "Silk-module--number23--2x1Cn";
export const number24 = "Silk-module--number24--2hWIR";