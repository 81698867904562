import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Button from '../Button/Button';
import Container from '../Container/Container';
import Title from '../Title/Title';
import * as styles from './GoogleMap.module.css';

const radiusOptions = [5, 10, 15, 20, 25, 30];

export default function GoogleMap({ title, markers = [], description }) {
  const mapRef = useRef();
  const [venues, setVenues] = useState(markers);
  const [search, setSearch] = useState('');
  const [radius, setRadius] = useState(10);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function init() {
      const map = new window.google.maps.Map(mapRef.current);
      const bounds = new window.google.maps.LatLngBounds();

      venues.forEach((markerItem) => {
        const { lat, lng } = markerItem;

        if (lat && lng) {
          const position = new window.google.maps.LatLng(lat, lng);
          bounds.extend(position);

          const marker = new window.google.maps.Marker({
            position,
            map,
            ...(markerItem.premier
              ? {
                  icon: '/assets/premier_marker.svg',
                }
              : {}),
            zIndex: 1,
          });

          window.google.maps.event.addListener(marker, 'click', () => {
            const infoWindow = new window.google.maps.InfoWindow();
            infoWindow.setContent(`
              <strong>${markerItem.name}</strong>
              <p>${markerItem.address}</p>
              ${markerItem.phone ? `<p>${markerItem.phone}</p>` : ''}
              <a href="${`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                markerItem.address
              )}`}" target="_blank" rel="noopener noreferrer">Get directions</a>
            `);
            infoWindow.open(map, marker);
          });
        }
      });

      map.fitBounds(bounds);

      const boundsListener = window.google.maps.event.addListener(
        map,
        'bounds_changed',
        () => {
          map.setZoom(10);
          // Run once, and then remove
          window.google.maps.event.removeListener(boundsListener);
        }
      );
    }

    if (venues.length > 0) {
      init();
    }
  }, [venues, title]);

  useEffect(() => {
    if (venues.length === 0 && search) {
      setErrorMessage(`No locations near ${search}, showing all locations.`);
      setVenues(markers);
    }
  }, [venues]);

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setErrorMessage('');

      if (search) {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            search
          )}&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`
        );
        const json = await response.json();
        const place = json.results[0];
        setSearch(place.formatted_address);

        const { lat, lng } = place.geometry.location;
        const searchedLatLng = new window.google.maps.LatLng(lat, lng);

        setVenues(
          markers.filter((venue) => {
            const venueLatLng = new window.google.maps.LatLng(
              venue.lat,
              venue.lng
            );
            const distanceFromLatLng = window.google.maps.geometry.spherical.computeDistanceBetween(
              venueLatLng,
              searchedLatLng
            ); // distance in meters between your location and the marker
            const distanceFromLatLngInMiles = distanceFromLatLng * 0.621371192; // A US thing
            return distanceFromLatLngInMiles <= radius * 1000;
          })
        );
      } else {
        setVenues(markers);
      }
    } catch (error) {
      setVenues([]);
    }
  }

  return markers.length > 0 ? (
    <Container>
      <Title>{title}</Title>
      {description ? <ReactMarkdown>{description}</ReactMarkdown> : null}
      <form onSubmit={handleSubmit}>
        <div className={styles.search}>
          <label
            htmlFor="search"
            className={clsx(styles.label, styles.searchLabel)}
          >
            Search
          </label>
          <label
            htmlFor="radius"
            className={clsx(styles.label, styles.radiusLabel)}
          >
            Within
          </label>
          <input
            type="text"
            id="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            className={styles.searchInput}
            placeholder="Search by address or postal code to find the nearest locations"
          />
          <select
            id="radius"
            value={radius}
            onChange={(event) => setRadius(event.target.value)}
            className={styles.selectInput}
          >
            {radiusOptions.map((option) => (
              <option key={option} value={option}>
                {option} miles
              </option>
            ))}
          </select>
          <Button type="submit" theme="primary" className={styles.submitButton}>
            Search
          </Button>
        </div>
        {errorMessage ? (
          <span className={styles.errorMessage}>{errorMessage}</span>
        ) : null}
      </form>
      <div ref={mapRef} className={styles.map} />
    </Container>
  ) : null;
}

GoogleMap.propTypes = {
  title: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.shape()),
  description: PropTypes.string,
};
