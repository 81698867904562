import dayjs from 'dayjs';
import useAppState from './useAppState';

const URGENT_SECONDS = 300;

export default function useCountdown(compareTime) {
  const appState = useAppState();
  const countdown = dayjs.duration(
    dayjs(compareTime).diff(appState.currentTime)
  );
  const urgent = countdown.asSeconds() < URGENT_SECONDS;
  const label = [
    {
      unit: 'd',
      value: countdown.days(),
    },
    {
      unit: 'h',
      value: countdown.hours(),
    },
    {
      unit: 'm',
      value: countdown.minutes(),
    },
    urgent
      ? {
          unit: 's',
          value: countdown.seconds(),
        }
      : {
          value: 0,
        },
  ]
    .reduce((aggregated, time) => {
      if (time.value === 0) {
        return aggregated;
      }

      return [...aggregated, `${time.value}${time.unit}`];
    }, [])
    .join(' ');

  return {
    urgent,
    label: countdown.asSeconds() < 0 ? 'POST' : label,
  };
}
