import PropTypes from 'prop-types';
import React from 'react';
import Carousel from './Carousel/Carousel';
import RacingCalendar from './RacingCalendar/RacingCalendar';
import ImageGrid from './ImageGrid/ImageGrid';
import Markdown from './Markdown/Markdown';
import NextToJump from './NextToJump/NextToJump';
import OrderedList from './OrderedList/OrderedList';
import Iframe from './Iframe/Iframe';

// This component is run on build
export default function Page({ location, data }) {
  const { sections = [] } = data;

  function render(section, i) {
    switch (section.type) {
      case 'carousel':
        return <Carousel key={i} {...section} />;
      case 'orderedList':
        return <OrderedList key={i} {...section} />;
      case 'imageGrid':
        return <ImageGrid key={i} {...section} />;
      case 'nextToJump':
        return <NextToJump key={i} {...section} />;
      case 'markdown':
        return <Markdown key={i} {...section} />;
      case 'racingCalendar':
        return <RacingCalendar key={i} {...section} location={location} />;
      case 'iframe':
        return <Iframe key={i} {...section} />;
      default:
        return null;
    }
  }

  return sections.map(render);
}

Page.propTypes = {
  data: PropTypes.shape(),
};
