import React from 'react';
import PropTypes from 'prop-types';
import * as icons from './icons';

export default function Icon({ name, className = '', ...rest }) {
  const IconComponent = icons[name] || icons.THOROUGHBRED; // Default to horse on missing exports

  return <IconComponent className={className} {...rest} />;
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};
