// extracted by mini-css-extract-plugin
export const meetingButton = "CalendarEvent-module--meetingButton--1D3Ck";
export const track = "CalendarEvent-module--track--2A33Z";
export const content = "CalendarEvent-module--content--3eYxy";
export const races = "CalendarEvent-module--races--22YHd";
export const raceButton = "CalendarEvent-module--raceButton--3eoiD";
export const FINAL = "CalendarEvent-module--FINAL--2pin8";
export const selected = "CalendarEvent-module--selected--k4g4e";
export const titleContainer = "CalendarEvent-module--titleContainer--Yp_vz";
export const competitors = "CalendarEvent-module--competitors--p0VXW";
export const competitor = "CalendarEvent-module--competitor--lO-CE";
export const ctaButton = "CalendarEvent-module--ctaButton--6adt2";