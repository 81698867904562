import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Link from '../Link';
import Title from '../Title/Title';
import * as styles from './OrderedList.module.css';

export default function OrderedList({ orderedListItems = [], title }) {
  return (
    <Container className={styles.container}>
      <Title>{title}</Title>
      <ol className={styles.list}>
        {orderedListItems.map((item, i) => (
          <li key={item.title} className={styles.listItem}>
            <Link href={item.url} className={styles.count}>
              {i + 1}
            </Link>
            <span className={styles.listTitle}>{item.title}</span>
            <p>{item.description}</p>
          </li>
        ))}
      </ol>
    </Container>
  );
}

OrderedList.propTypes = {
  orderedListItems: PropTypes.arrayOf(PropTypes.shape()),
  title: PropTypes.string,
};
