import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import PageContext from '../../contexts/PageContext';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import Button from '../Button/Button';
import Link from '../Link';
import * as styles from './Carousel.module.css';

export default function Carousel({ carouselItems = [] }) {
  const autoplayRef = useRef();
  const [selected, setSelected] = useState(0);
  const [autoplay, setAutoplay] = useState(true);
  const headerHeight = useHeaderHeight();
  const { navigation } = useContext(PageContext);

  const goToNext = useCallback(() => {
    setSelected((prev) => (prev < carouselItems.length - 1 ? prev + 1 : 0));
  }, [carouselItems]);

  function goToPrevious() {
    setSelected((prev) => (prev > 0 ? prev - 1 : carouselItems.length - 1));
  }

  useEffect(() => {
    clearTimeout(autoplayRef.current);

    if (autoplay) {
      autoplayRef.current = setTimeout(goToNext, 7000);
    }

    return () => {
      clearTimeout(autoplayRef.current);
    };
  }, [autoplay, selected, goToNext]);

  function goToSelected(i) {
    setSelected(i);
    setAutoplay(false);
  }

  return (
    <div
      className={styles.container}
      style={{ height: `calc(100vh - ${headerHeight}px)` }}
    >
      <div
        className={styles.carousel}
        style={{
          width: `${carouselItems.length * 100}%`,
          transform: `translateX(-${(selected / carouselItems.length) * 100}%)`,
        }}
      >
        {carouselItems.map((carouselItem) => (
          <div
            key={carouselItem.title}
            className={styles.carouselItem}
            style={{ color: carouselItem.fontColor }}
          >
            <div
              className={styles.backgroundImage}
              style={{
                backgroundImage: `url(${carouselItem.backgroundImage})`,
              }}
            />
            <div className={styles.shadow} />
            <span className={styles.title}>{carouselItem.title}</span>
            {carouselItem.description ? (
              <ReactMarkdown className={styles.markdown}>
                {carouselItem.description}
              </ReactMarkdown>
            ) : null}
            {carouselItem.url ? (
              <Link href={carouselItem.url} className={styles.anchor}>
                Click here for more information
              </Link>
            ) : null}
            {carouselItem.showCallToActionItems &&
            (navigation?.callToActionItems || []).length > 0 ? (
              <div className={styles.callToActionContainer}>
                {navigation.callToActionItems.map((callToAction) => (
                  <Button
                    key={callToAction.label}
                    href={callToAction.url}
                    theme={callToAction.theme}
                    className={styles.callToActionButton}
                  >
                    {callToAction.label}
                  </Button>
                ))}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <button
        type="button"
        className={clsx(styles.arrowButton, styles.leftButton)}
        onClick={goToPrevious}
      >
        <span>&lt;</span>
      </button>
      <div className={styles.quickContainer}>
        {carouselItems.map((carouselItem, i) => (
          <button
            key={carouselItem.title}
            type="button"
            aria-label={`${i} carousel item`}
            className={clsx(styles.quickButton, {
              [styles.selected]: selected === i,
            })}
            onClick={() => goToSelected(i)}
          />
        ))}
      </div>
      <button
        type="button"
        className={clsx(styles.arrowButton, styles.rightButton)}
        onClick={goToNext}
      >
        <span>&gt;</span>
      </button>
    </div>
  );
}

Carousel.propTypes = {
  carouselItems: PropTypes.arrayOf(PropTypes.shape()),
};
