// extracted by mini-css-extract-plugin
export const container = "Carousel-module--container--2ymTW";
export const carousel = "Carousel-module--carousel--dROcc";
export const carouselItem = "Carousel-module--carouselItem--1wWj4";
export const backgroundImage = "Carousel-module--backgroundImage--2MhRW";
export const shadow = "Carousel-module--shadow--2DPCb";
export const title = "Carousel-module--title--rDMkO";
export const anchor = "Carousel-module--anchor--1dO3E";
export const markdown = "Carousel-module--markdown--3omfS";
export const quickContainer = "Carousel-module--quickContainer--3wLqn";
export const quickButton = "Carousel-module--quickButton--2ISXi";
export const selected = "Carousel-module--selected--4wjZ9";
export const arrowButton = "Carousel-module--arrowButton--Z9R8L";
export const leftButton = "Carousel-module--leftButton--2s5aK";
export const rightButton = "Carousel-module--rightButton--18-BP";
export const callToActionContainer = "Carousel-module--callToActionContainer--tUORq";
export const callToActionButton = "Carousel-module--callToActionButton--zA-BS";