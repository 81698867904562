import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Link from '../Link';
import Title from '../Title/Title';
import * as styles from './ImageGrid.module.css';

export default function ImageGrid({ title, imageGridItems = [] }) {
  return (
    <Container>
      <Title>{title}</Title>
      <div className={styles.grid}>
        {imageGridItems.map((item) =>
          item.url ? (
            <Link
              href={item.url}
              key={item.title}
              className={styles.imageContainer}
            >
              <img src={item.image} alt={item.title} className={styles.image} />
            </Link>
          ) : (
            <div
              href={item.url}
              key={item.title}
              className={styles.imageContainer}
            >
              <img src={item.image} alt={item.title} className={styles.image} />
            </div>
          )
        )}
      </div>
    </Container>
  );
}

ImageGrid.propTypes = {
  title: PropTypes.string,
  imageGridItems: PropTypes.arrayOf(PropTypes.shape()),
};
