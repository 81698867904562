import PropTypes from 'prop-types';
import React from 'react';
import Container from '../Container/Container';
import Title from '../Title/Title';
import GoogleMap from '../GoogleMap/GoogleMap';
import StyledMarkdown from '../Markdown/StyledMarkdown';
import * as styles from './Location.module.css';

// This component is run on build
export default function Location({ data, pageContext }) {
  const { title, venues } = data;

  return (
    <Container>
      <Title>{title}</Title>
      {venues?.map((venue) => (
        <div className={styles.venueContainer} key={venue.name}>
          <div className={styles.imageContainer}>
            {venue.image ? (
              <img
                src={venue.image}
                className={styles.image}
                alt={venue.name}
              />
            ) : null}
          </div>
          <div className={styles.venueDescription}>
            <h3>{venue.name}</h3>
            <p>{venue.address}</p>
            {venue.phone ? <p>{venue.phone}</p> : null}
            <StyledMarkdown description={venue.description} />
          </div>
        </div>
      ))}
      {pageContext?.location ? (
        <GoogleMap markers={pageContext?.location.venues} />
      ) : null}
    </Container>
  );
}

Location.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
};
