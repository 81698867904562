import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import Location from '../components/Location/Location';
import Locations from '../components/Locations/Locations';
import Page from '../components/Page';
import PageLayout from './PageLayout';

// This component is run on build
export default function PageTemplate({ location, data, pageContext }) {
  const { type } = pageContext;

  function renderTemplate() {
    switch (type) {
      case 'locations':
        return (
          <Locations
            data={data.markdownRemark.frontmatter}
            pageContext={pageContext}
          />
        );
      case 'location':
        return (
          <Location
            data={data.markdownRemark.frontmatter}
            pageContext={pageContext}
          />
        );
      default:
        return (
          <Page
            data={data.markdownRemark.frontmatter}
            pageContext={pageContext}
            location={location}
          />
        );
    }
  }

  return (
    <PageLayout data={data.markdownRemark.frontmatter}>
      {renderTemplate()}
    </PageLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        venues {
          name
          address
          image
          phone
          description
          premier
        }
        sections {
          type
          title
          url
          description
          buttonColor
          fontColor
          blacklistedTracks {
            name
          }
          carouselItems {
            title
            url
            backgroundImage
            description
            showCallToActionItems
            fontColor
          }
          orderedListItems {
            title
            description
            url
          }
          imageGridItems {
            title
            image
            url
          }
        }
      }
    }
  }
`;

PageTemplate.propTypes = {
  data: PropTypes.shape().isRequired,
  pageContext: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired,
};
